



































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  // fractalUserInfoLink = process.env.VUE_APP_KYC_FRONTEND_DOMAIN
  dialog = false
  wallet = walletStore
}
